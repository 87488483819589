import React, { memo, useMemo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Typography,
} from "../../../../../components";
import { omit } from "lodash";
import { DeleteForever } from "../../../../../icons";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { makeStyles } from "@material-ui/core/styles";
import VoiceFileInput from "../VoiceFileInput";
import { useDispatch } from "react-redux";
import {
  buildMasterTemplate,
  uploadWavFile,
} from "../../../../../actions/masterTemplate";
import { createFilePath } from "../../../../Campaigns/Single/VoicePreview";

const ACTION_TRANSFER = "transfer";
const ACTION_PLAYBACK = "playback";
const ACTION_GO_TO = "goto";
const actionTypes = [ACTION_TRANSFER, ACTION_PLAYBACK, ACTION_GO_TO];
const keys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "#", "*"];

const useStyles = makeStyles({
  sms: {
    height: 40,
  },
});

const getOrderRecipientHeaders = (recipientHeaders) =>
  Object.keys(recipientHeaders).sort();

function VoiceDTMFInput({
  idx,
  readOnly,
  value = [],
  onChange,
  onDTMFFilePathChange,
  onSaveAsDraftInitial,
  gotoOptions,
  children,
  path,
}) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [showUploadFileBtn, setShowUploadFileBtn] = useState(
    path ? false : true
  );

  const classes = useStyles();

  const recipientHeaders = useSelector(
    (state) => state.eonsConfig.vendorConfig.recipientHeaders
  );

  const masterTemplate = useSelector((state) => state.masterTemplate ?? {});
  const { errors = {} } = masterTemplate;

  const addAction = useCallback(() => {
    onChange(idx, [...value, {}]);
  }, [idx, onChange, value]);

  const onSelectAction = useCallback(
    (ev, stepIdx) => {
      const { key } = value[stepIdx];
      value[stepIdx] = { key }; // reset
      value[stepIdx]["action"] = ev.target.value;
      onChange(idx, value);
    },
    [idx, onChange, value]
  );

  const onSelectKey = useCallback(
    (ev, stepIdx) => {
      value[stepIdx]["key"] = ev.target.value;
      onChange(idx, value);
      let newErrors = {};
      if (masterTemplate?.voiceTemplate?.optOutKey == ev.target.value) {
        newErrors = {
          KeyShouldMismatch: "Should not match Opt-out key",
        };
      } else {
        newErrors = omit(errors, "KeyShouldMismatch");
      }
      dispatch(
        buildMasterTemplate({
          errors: newErrors,
        })
      );
    },
    [dispatch, errors, onChange, idx, value]
  );

  const onSelectGoto = useCallback(
    (ev, stepIdx) => {
      value[stepIdx]["step"] = ev.target.value;
      onChange(idx, value);
    },
    [idx, onChange, value]
  );

  const onChangeTransfer = useCallback(
    (sms, stepIdx) => {
      value[stepIdx]["transferNumber"] = sms;
      onChange(idx, value);
    },
    [onChange, idx, value]
  );

  const onDelete = useCallback(() => {
    setShowUploadFileBtn(true);
    onDTMFFilePathChange(idx, "");
  }, [onDTMFFilePathChange, idx]);

  const onRejectVoiceFile = useCallback((stepIdx) => {
    // dispatch()
    // TODO:
    // eslint-disable-next-line no-console
    console.log("ERROR on upload voice file in dtmf");
  });

  const onUploadVoiceFileDTMF = useCallback(
    async ([file], stepIdx) => {
      try {
        const path = await dispatch(uploadWavFile(file));
        value[stepIdx]["path"] = path;
        onChange(idx, value);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        // TODO
      }
    },
    [dispatch, value, onChange, idx]
  );

  const onUploadBaseVoiceFileDTMF = useCallback(
    async ([file]) => {
      try {
        await onSaveAsDraftInitial();
        const path = await dispatch(uploadWavFile(file));
        onDTMFFilePathChange(idx, path);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    [dispatch, onDTMFFilePathChange, onSaveAsDraftInitial, idx]
  );

  const deleteStep = useCallback(
    (stepIdx) => {
      onChange(
        idx,
        value.filter((_, i) => i !== stepIdx)
      );
    },
    [onChange, idx, value]
  );

  const takenKeys = value
    .map((step) => step.key)
    .filter((key) => key !== undefined);
  const availKeys = keys.filter((key) => takenKeys.indexOf(key) < 0);

  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);
  return (
    <Grid
      container
      spacing={2}
      style={{
        border: "1px solid #d3d4d5",
        margin: "0.2em",
        borderRadius: "5px",
      }}
    >
      <Grid item xs={12} sm={12} md={12}>
        <Typography>{t("DTMF")}</Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <Grid container direction="column" spacing={2}>
          {value.map((step, stepIdx) => (
            <Grid item key={stepIdx}>
              <Grid container spacing={1} wrap="nowrap" alignItems="flex-start">
                <Grid item xs>
                  <TextField
                    disabled={readOnly}
                    data-test-id={`master-template-voice-dtmf-key-${idx}-${stepIdx}`}
                    select
                    label={t("Key")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={step.key ? step.key : " "}
                    onChange={(ev) => onSelectKey(ev, stepIdx)}
                    InputProps={readOnlyProps}
                    error={Boolean(errors["KeyShouldMismatch"])}
                    helperText={errors["KeyShouldMismatch"]}
                  >
                    {[step.key ? step.key : "-", ...availKeys].map((header) => (
                      <MenuItem key={header} value={header}>
                        {header}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs>
                  <TextField
                    disabled={readOnly}
                    data-test-id={`master-template-voice-dtmf-data-field-${idx}-${stepIdx}`}
                    select
                    label={t("Action")}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={step.action ? step.action : ""}
                    onChange={(ev) => {
                      onSelectAction(ev, stepIdx);
                    }}
                    InputProps={readOnlyProps}
                    error={null}
                    helperText={null}
                  >
                    {actionTypes.map((header) => (
                      <MenuItem key={header} value={header}>
                        {header}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item>
                  {(() => {
                    const { action } = step;
                    switch (action) {
                      case ACTION_TRANSFER:
                        return (
                          <PhoneInput
                            disabled={readOnly}
                            data-test-id={`master-template-action-transfer-${idx}-${stepIdx}`}
                            inputClass={classes.sms}
                            label={t("Transfer Number")}
                            country={"us"}
                            value={
                              step.transferNumber
                                ? `${step.transferNumber}`
                                : ""
                            }
                            onChange={(sms) => onChangeTransfer(sms, stepIdx)}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        );
                      case ACTION_PLAYBACK:
                        return (
                          <VoiceFileInput
                            idx={stepIdx}
                            readOnly={readOnly}
                            error={null}
                            onUpload={onUploadVoiceFileDTMF}
                            onReject={onRejectVoiceFile}
                            label={
                              truncateLongString(parseFileName(step.path)) ||
                              t(
                                "Upload an audio file by clicking or drag-and-drop"
                              )
                            }
                          />
                        );
                      case ACTION_GO_TO:
                        return (
                          <TextField
                            disabled={readOnly}
                            data-test-id={`master-template-voice-dtmf-goto-${idx}-${stepIdx}`}
                            select
                            label={t("Go to Step")}
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={step.step ? step.step : " "}
                            onChange={(ev) => onSelectGoto(ev, stepIdx)}
                            InputProps={readOnlyProps}
                            error={null}
                            helperText={null}
                          >
                            {gotoOptions.map((header) => (
                              <MenuItem key={header} value={header}>
                                {header}
                              </MenuItem>
                            ))}
                          </TextField>
                        );
                      default:
                        return "";
                    }
                  })()}
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={readOnly}
                    data-test-id={`master-template-delete-voice-dtmf-${stepIdx}`}
                    size="small"
                    color="secondary"
                    onClick={() => deleteStep(stepIdx)}
                  >
                    <DeleteForever />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item>
            {showUploadFileBtn ? (
              <Grid container spacing={2} justify="flex-end">
                <Grid item>
                  <Button
                    disabled={readOnly}
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={() => setShowUploadFileBtn(false)}
                    error={false}
                  >
                    {t("Add Voice File")}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container alignItems="flex-start">
                <Grid item xs={11} md={11} sm={11}>
                  <VoiceFileInput
                    readOnly={readOnly}
                    error={null}
                    onUpload={onUploadBaseVoiceFileDTMF}
                    onReject={onRejectVoiceFile}
                    label={
                      truncateLongString(parseFileName(path)) ||
                      t("Upload an audio file by clicking or drag-and-drop")
                    }
                  />
                  {path ? (
                    <audio
                      controls
                      className="my-2"
                      style={{ height: "30px", width: "100%" }}
                    >
                      <source src={createFilePath(path)} type="audio/mpeg" />
                      Your browser does not support the HTML audio
                    </audio>
                  ) : null}
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={readOnly}
                    data-test-id={`master-template-delete-voice-item-button-${idx}`}
                    size="small"
                    color="secondary"
                    onClick={() => onDelete()}
                  >
                    <DeleteForever />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Grid container spacing={2} justify="flex-end">
              <Grid item>
                <Button
                  disabled={readOnly}
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  onClick={addAction}
                  error={false}
                >
                  {t("Add DTMF Step")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function truncateLongString(str) {
  if (!str) {
    return false;
  }
  const maxLength = 50;
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
}

function parseFileName(path) {
  if (!path) return false;

  const arr = path.split("/");
  return arr[arr.length - 1];
}

export default memo(VoiceDTMFInput);
